<script>
import Layout from "@/router/layouts/store";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Franquias': 'Franquias',
    },
    es: {
      'Franquias': 'Franquias',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      franchises: ''
    };
  },
  methods: {
    getList() {
      api
        .get('store/franchises')
        .then((response) => {
          if (response.data.status == 'success') {
            this.franchises = response.data.list
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-22">{{ t('Franquias') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" v-for="(fra, index) in franchises" :key="index">
        <div class="card">
          <div class="card-header">
            {{ fra.name }}
          </div>
          <div class="card-body">
            <h6 class="mb-0">
              <i class="bx bx-user-pin font-size-20 align-middle"></i> {{ fra.responsible }}
            </h6>
            <h6 class="mb-0">
              <i class="bx bx-microphone font-size-20 align-middle"></i> {{ fra.phone }}
            </h6>
            <h6 class="mb-0">
              <i class="bx bx-mail-send font-size-20 align-middle"></i> {{ fra.email }}
            </h6>
            <h6 class="mt-3 mb-0 d-flex">
              <i class="bx bxs-map font-size-20 align-middle"></i>
              <div class="ml-1">
                {{ fra.company.address }},
                {{ fra.company.number }}<template>, {{ fra.company.complement }}</template><br>
                {{ fra.company.district }},
                {{ fra.company.city }}/{{ fra.company.state }},
                {{ fra.company.zipcode }}
              </div>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
